import { CreditorModelDto, WireModelDto } from '@treasury/api/channel';
import { Nullable, clone, createUniqueId } from '@treasury/utils';
import { DomainEntity } from '../shared';
import { WireAccount } from './wire-account.entity';
import { WireBeneficiary } from './wire-beneficiary.entity';
import { WireCompany } from './wire-company.entity';
import { WireTemplateEntryType } from './wire-template.dto';
import { nullWire } from './wire.dto';

export enum WireStatus {
    PendingApproval = 'Pending Approval',
    ApprovalRejected = 'Approval Rejected',
    Transmitted = 'Transmitted',
    Canceled = 'Canceled',
    Posted = 'Posted',
    Scheduled = 'Scheduled',
    Failed = 'Failed',
    Expired = 'Expired',
    Deleted = 'Deleted',
}

export class Wire extends DomainEntity<Nullable<WireModelDto>> {
    constructor(dto = clone(nullWire)) {
        super(dto);
        this.dto = {
            ...nullWire,
            ...dto,
        };
        this.dto.duplicatePreventionId = this.dto.duplicatePreventionId ?? createUniqueId();
    }

    private _isChecked = false;

    public readonly type: WireTemplateEntryType = this.dto.isInternational
        ? 'International'
        : 'Domestic';

    private _createdDate = this.dto.createdDate ? new Date(this.dto.createdDate) : undefined;

    private _effectiveDate = this.dto.frequency?.valueDate
        ? new Date(this.dto.frequency.valueDate)
        : undefined;

    public createDefault(): this {
        return new Wire(this.dto) as this;
    }

    public get additionalInformation() {
        return this.dto.additionalInformation;
    }

    public set additionalInformation(additionalInformation) {
        this.dto.additionalInformation = additionalInformation;
    }

    public get additionalInformationValues() {
        return this.additionalInformation?.map(line => line.value);
    }

    public get amount() {
        return this.dto.amount || 0;
    }

    public set amount(amount) {
        this.dto.amount = amount;
    }

    public get audit() {
        return this.dto.audit;
    }

    public set audit(audit) {
        this.dto.audit = audit;
    }

    public get availableApprovers() {
        return this.dto.availableApprovers;
    }

    public set availableApprovers(availableApprovers) {
        this.dto.availableApprovers = availableApprovers;
    }

    public get beneficiary() {
        return this.dto.beneficiary as CreditorModelDto;
    }

    public set beneficiary(beneficiary) {
        this.dto.beneficiary = beneficiary;
    }

    public get completedApprovals() {
        return this.dto.completedApprovals;
    }

    public set completedApprovals(completedApprovals) {
        this.dto.completedApprovals = completedApprovals;
    }

    public get completedApprovalCount() {
        return this.dto.completedApprovalCount;
    }

    public set completedApprovalCount(completedApprovalCount) {
        this.dto.completedApprovalCount = completedApprovalCount;
    }

    public get confirmationNumber() {
        return this.dto.confirmationNumber;
    }

    public set confirmationNumber(confirmationNumber) {
        this.dto.confirmationNumber = confirmationNumber;
    }

    public get createdBy() {
        return this.dto.createdBy;
    }

    public set createdBy(createdBy) {
        this.dto.createdBy = createdBy;
    }

    public get createdDate() {
        return this._createdDate;
    }

    public set createdDate(date) {
        this._createdDate = date;
    }

    public get debitAccount() {
        return this.dto.debitAccount;
    }

    public set debitAccount(debitAccount) {
        this.dto.debitAccount = debitAccount;
    }

    public get destinationCurrency() {
        return this.dto.destinationCurrency || '';
    }

    public set destinationCurrency(destinationCurrency) {
        this.dto.destinationCurrency = destinationCurrency;
    }

    public get duplicatePreventionId() {
        return this.dto.duplicatePreventionId;
    }

    public set duplicatePreventionId(duplicatePreventionId) {
        this.dto.duplicatePreventionId = duplicatePreventionId;
    }

    public get effectiveDate() {
        return this._effectiveDate;
    }

    public set effectiveDate(date) {
        this._effectiveDate = date;
    }

    public get fileName() {
        return this.dto.fileName;
    }

    public set fileName(fileName) {
        this.dto.fileName = fileName;
    }

    public get frequency() {
        return this.dto.frequency;
    }

    public set frequency(frequency) {
        this.dto.frequency = frequency;
    }

    public get isChecked() {
        return this._isChecked;
    }

    public set isChecked(isChecked) {
        this._isChecked = isChecked;
    }

    public get isDliWire() {
        return this.dto.isDliWire;
    }

    public set isDliWire(isDliWire) {
        this.dto.isDliWire = isDliWire;
    }

    public get isEdited() {
        return this.dto.isEdited;
    }

    public set isEdited(isEdited) {
        this.dto.isEdited = isEdited;
    }

    public get isInternational() {
        return this.dto.isInternational;
    }

    public set isInternational(isInternational) {
        this.dto.isInternational = isInternational;
    }

    public get message() {
        return this.dto.message;
    }

    public set message(message) {
        this.dto.message = message;
    }

    public get messageType() {
        return this.dto.messageType;
    }

    public set messageType(messageType) {
        this.dto.messageType = messageType;
    }

    public get numberOfApprovalsNeeded() {
        return this.dto.numberOfApprovalsNeeded;
    }

    public set numberOfApprovalsNeeded(numberOfApprovalsNeeded) {
        this.dto.numberOfApprovalsNeeded = numberOfApprovalsNeeded;
    }

    public get parentFrequency() {
        return this.dto.parentFrequency;
    }

    public set parentFrequency(parentFrequency) {
        this.dto.parentFrequency = parentFrequency;
    }

    public get permissions() {
        return this.dto.permissions;
    }

    public get purpose() {
        return this.dto.purpose;
    }

    public set purpose(purpose) {
        this.dto.purpose = purpose;
    }

    public get rateQuote() {
        return this.dto.rateQuote;
    }

    public set rateQuote(rateQuote) {
        this.dto.rateQuote = rateQuote;
    }

    public get referenceBeneficiary() {
        return this.dto.referenceBeneficiary;
    }

    public set referenceBeneficiary(referenceBeneficiary) {
        this.dto.referenceBeneficiary = referenceBeneficiary;
    }

    public get wireId() {
        return this.dto.id;
    }

    public set wireId(wireId) {
        this.dto.id = wireId;
    }

    public get securityMessage() {
        return this.dto.securityMessage;
    }

    public set securityMessage(securityMessage) {
        this.dto.securityMessage = securityMessage;
    }

    public get errorSummary() {
        return this.dto.errorSummary;
    }

    public get source() {
        return this.dto.source;
    }

    public set source(source) {
        this.dto.source = source;
    }

    public get status() {
        return this.dto.status;
    }

    public set status(status) {
        this.dto.status = status;
    }

    public get transactionId() {
        return this.dto.transactionId || '';
    }

    public set transactionId(transactionId) {
        this.dto.transactionId = transactionId;
    }

    public get wireCompany() {
        return this.dto.wireCompany;
    }

    public set wireCompany(wireCompany) {
        this.dto.wireCompany = wireCompany;
    }

    public get wireFileId() {
        return this.dto.wireFileId;
    }

    public set wireFileId(wireFileId) {
        this.dto.wireFileId = wireFileId;
    }

    public get wireTemplate() {
        return this.dto.wireTemplate;
    }

    public set wireTemplate(wireTemplate) {
        this.dto.wireTemplate = wireTemplate;
    }

    public get isValid() {
        return !!(this.amount && this.amount > 0 && this.purpose && this.purpose.length);
    }

    public get beneficiaryEntity() {
        return this.dto.beneficiary as unknown as WireBeneficiary;
    }

    public get debitAccountEntity() {
        return this.dto.debitAccount as unknown as WireAccount;
    }

    public get wireCompanyEntity() {
        return this.dto.wireCompany as unknown as WireCompany;
    }

    public toDto() {
        return this.dto;
    }

    public toCreateDto() {
        return {
            ...this.dto,
            beneficiary: this.beneficiaryEntity.toDto(),
            debitAccount: this.debitAccountEntity.toDto(),
            wireCompany: this.wireCompanyEntity.toDto(),
        };
    }
}
